<template>
   <div>
     <v-banner
        single-line
        height="500"
        class="banner"
    >
      <v-img
            src="https://h5.ophyer.cn/official_website/banner/wenboPCbanner.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">文博数字化展示</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF">
                     企业3D/VR数字化互动展示专家
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img src="https://h5.ophyer.cn/official_website/banner/wenboPCbanner-02.png"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>

    <!-- 功能 -->
    <v-card class="warp warp2" :flat="true">
      <div class="public-title">
        <p>APAAS ENGINE </p>
        <div>
          <span>功能</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs mt-8">
        <v-tab v-for="(v, k) in list" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in list" :key="k" transition="fade-transition">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k"><img :src="v.img" /></div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <div class="right" v-show="tab == k">
                <v-card-text class="title" v-text="v.h2" />
                <div class="line"></div>
                <v-card-text class="sub" v-text="v.sub" />
                <v-card-text class="tip" v-text="v.content" v-html="v.content" />
              </div>
            </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!--  -->
    <v-card class="justify-center warp3" :flat="true" style="margin-top:70px;margin-bottom: 38px;padding: 10px 0;background: #FBFBFB;">
      <div class="public-title">
        <p>APPLICATION SCENARIO</p>
        <div>
          <span>应用场景</span>
        </div>
      </div>
      <v-container class="max-width-1200 mt-11">
        <v-row  justify='space-between' >  
          <v-col cols="4"  sm="4" v-for="n in advantage" :key="n.id">
            <v-card flat class="advantage_card" >
              <div class="img_title" justify="center">
                <v-img :src="n.src" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                </v-img>
                <v-card-text class="pa-0 mt-1 ml-1 text-align-center">
                   <span class="font-size-16  line-height-17">{{n.title}}</span>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- 方案优势 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>APAAS ENGINE</p>
        <div>
          <span>方案优势</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in Vorteil" :key="k" cols="12" sm="3" >
            <div class="box">
              <img :src='v.src' />
              <v-card-title> {{ v.title }} </v-card-title>
              <v-card-text class="tip"> {{ v.content }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 精彩案列 -->
    <v-container class="justify-center mb-6 wap_case" >
     <div class="public-title">
       <p>WONDERFUL CASE</p>
       <div>
          <span>案例</span>
       </div>
     </div>
     <v-container style="max-width:1200px;margin-top:62px">
           <v-row>
               <v-col v-for="(card, index) in anli" :key="index" cols="3" class="mt-4">
                   <v-card
                      class="mx-auto item-card"
                      max-width="240"
                      max-height="410"
                      color="transparent"
                      @click="goCaseDetail(card)"
                    >
                      <v-img
                        max-height="340"
                        :src="card.coverImg"
                        :aspect-ratio="9/16"
                      >
                      <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                  indeterminate
                                  size="80"
                                  color="red lighten-5"
                                ></v-progress-circular>
                              </v-row>
                      </template>
                      </v-img>
                      <v-card-text class="font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>
                    </v-card>
                </v-col>
            </v-row>
      </v-container>
    </v-container>
    
   </div>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import contentData from '@/static/contentData.js';
import qs from 'qs'
export default {
  name: "OnlineRetailers",
  data(){
    return{
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      tab:null,
      list:[
        {title:"文物3D数字化",h2:'文物3D数字化',sub:'3D建模，真实还原历史文物',img:'https://h5.ophyer.cn/official_website/other/wenbo1-1.png',content:'• 制作高精度3D模型<br/>• 通过云端技术渲染后推送到客户端<br/>• 搭配3D动画，让文物的展示更鲜活'},
        {title:"3D虚拟展馆",h2:'3D虚拟展馆',sub:'个性化定制 更好的展示展览主题',img:'https://h5.ophyer.cn/official_website/other/wenbo1-2.png',content:'• 制作高精度3D模型<br/>• 按照客户需求设计，满足个性化需求'},
        {title:"VR实景展馆复刻",h2:'完美还原线下场馆',sub:'点击即可查看商品详情',img:'https://h5.ophyer.cn/official_website/other/wenbo1-3.png',content:'• 720°可交互全景，百分百还原线下展厅<br/>• 全景编辑制作平台，简单操作即可生成场景<br/>• 热点、嵌入、虚拟人物等特色功能，让展馆更智能'},
      ],
      Vorteil:contentData.DigitalMuseum.warp1.charList,
      advantage:[
        { title: '3D数字博物馆', src: 'https://h5.ophyer.cn/official_website/other/wenbo2-1.png'},
        { title: '文物3D数字化保护', src: 'https://h5.ophyer.cn/official_website/other/wenbo2-2.png'},
        { title: 'VR数字展馆建设', src: 'https://h5.ophyer.cn/official_website/other/wenbo2-3.png'},
      ],
     anli:[textData.anli.hanfuwenhuazhou,textData.anli.qiaojiadayuan,textData.anli.hongcunjiyi,textData.anli.anlongyingshi],
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>


.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  border-radius: 8px;
  &::after {
      content: "";
      background-image: url("../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &:focus{
     display: none;
  }
}
.wap_case{
  background: #FBFBFB;
  padding: 10px 0;
  margin-top: 40px;
  .public-title{
    margin: 45px auto 65px !important;
  }
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}
.warp1 {
  .con {
    width: 1080px;
    margin: 20px auto 0;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .sub{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-top: 16px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #000000;
      margin-top: 5px;
    }
    .tip {
      margin: 10px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
::v-deep .warp3{
  .max-width-1200{
    width: 1080px;
  }
  .theme--light.v-card{
    background: #FBFBFB !important
  }
}
.img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 30px;
      border-radius: 10px;
      // width:400px ;
      // height: 260px;
      object-fit: cover;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
::v-deep .advantage_card{
  transition: 0.3s;
  width: 300px;
  height: 206px;
  margin: 0 auto 60px;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
      background: #FBFBFB !important
    }
}
</style>